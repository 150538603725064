<script>
    // import {UTooltip} from "uloc-vue";

    export default {
      name: 'task-checklist-tab',
      data () {
        return {
          content: []
        }
      },
      computed: {
        filteredData () {
          let data = this.content
          let sortKey = 'date'
          let order = -1

          // Force order by id, if date equals
          data = data.slice().sort(function (a, b) {
            a = a['id']
            b = b['id']
            return (a === b ? 0 : a > b ? 1 : -1) * -1
          })

          if (sortKey) {
            data = data.slice().sort(function (a, b) {
              a = a[sortKey]
              b = b[sortKey]
              return (a === b ? 0 : a > b ? 1 : -1) * order
            })
          }
          return data
        },
        classes () {
          let css = []

          return css
        },
      },
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {}
    }
</script>

<template>
    <div class="task-checklist flex items-center justify-center w-full" :class="classes">
        <div>
            <div class="text-center m-b" style="color: #BBBBBB; font-size: 80px"><u-icon name="clipboard-list-check" type="fa" icon-style="light"/></div>
            <div>Checklist não disponível nesta versão</div>
        </div>
    </div>
</template>
